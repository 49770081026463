"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class Token {
    constructor(type, lexeme, values, location) {
        this.type = type;
        this.lexeme = lexeme;
        this.values = values;
        this.location = location;
    }
    formatValues() {
        const formatted = [];
        const valueNames = Object.keys(this.values);
        for (let i = 0; i < valueNames.length; i++) {
            formatted.push(`${valueNames[i]}=${this.values[valueNames[i]]}`);
        }
        return formatted.join(', ');
    }
    toString() {
        return `${this.type.toString()}[${this.formatValues()}] ${this.lexeme} at ${this.location}`;
    }
}
exports.Token = Token;
