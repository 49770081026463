exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".editor-___ParserOverlay__overlay___ZOcLQ {\n  border-style: none;\n  min-width: 400px;\n  font-size: 16px;\n  line-height: 1.3rem;\n  background-color: rgba(255, 255, 255, 0.8);\n}\n\n.editor-___ParserOverlay__node-null___2Q0iG, .editor-___ParserOverlay__node-undefined___2-6Nx {\n  color: #aaa;\n}\n\n.editor-___ParserOverlay__node-string___3Xk4J {\n  color: #66aa66\n}\n\n.editor-___ParserOverlay__node-number___3yx4_ {\n  color: #aa6666;\n}\n\n.editor-___ParserOverlay__node-boolean___13JhB {\n  color: #6666aa;\n}\n\n.editor-___ParserOverlay__node-object___34Ym4, .editor-___ParserOverlay__node-array___cqAA2 {\n  color: #666;\n  cursor: pointer;\n}\n\n.editor-___ParserOverlay__node-object___34Ym4 + ul, .editor-___ParserOverlay__node-array___cqAA2 + ul {\n  list-style: none;\n  padding-left: 25px;\n  margin: 0;\n}\n\n.editor-___ParserOverlay__mono___1zWD1 {\n  font-family: monospace;\n}", ""]);

// Exports
exports.locals = {
	"overlay": "editor-___ParserOverlay__overlay___ZOcLQ",
	"node-null": "editor-___ParserOverlay__node-null___2Q0iG",
	"node-undefined": "editor-___ParserOverlay__node-undefined___2-6Nx",
	"node-string": "editor-___ParserOverlay__node-string___3Xk4J",
	"node-number": "editor-___ParserOverlay__node-number___3yx4_",
	"node-boolean": "editor-___ParserOverlay__node-boolean___13JhB",
	"node-object": "editor-___ParserOverlay__node-object___34Ym4",
	"node-array": "editor-___ParserOverlay__node-array___cqAA2",
	"mono": "editor-___ParserOverlay__mono___1zWD1"
};