"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var Rex_1 = require("./Rex");
function unicodeRead(string, cursor) {
    var char;
    if (cursor < string.length) {
        char = string[cursor];
        var charCode = char.charCodeAt(0);
        if (charCode >= 0x0D00 && charCode <= 0xDBFF) {
            // might be a two-character unicode
            if (cursor + 1 < string.length) {
                var peekChar = string[cursor + 1];
                var peekCharCode = peekChar.charCodeAt(0);
                if (peekCharCode >= 0xDC00 && peekCharCode <= 0xDFFF) {
                    char += peekChar;
                }
            }
        }
    }
    return char;
}
exports.unicodeRead = unicodeRead;
exports.MATCH_TYPE_NORMAL = Symbol('MATCH_TYPE_NORMAL');
exports.MATCH_TYPE_ZERO_OR_ONE = Symbol('MATCH_TYPE_ZERO_OR_ONE');
exports.MATCH_TYPE_ZERO_OR_MORE = Symbol('MATCH_TYPE_ZERO_OR_MORE');
exports.MATCH_TYPE_ONE_OR_MORE = Symbol('MATCH_TYPE_ONE_OR_MORE');
var RexNode = /** @class */ (function () {
    function RexNode() {
        this.matchType = exports.MATCH_TYPE_NORMAL;
        this.isNonGreedy = false;
    }
    RexNode.prototype.setMatchType = function (matchType) {
        this.matchType = matchType;
    };
    RexNode.prototype.getMatchType = function () {
        return this.matchType;
    };
    return RexNode;
}());
exports.RexNode = RexNode;
var RexMatchAny = /** @class */ (function (_super) {
    __extends(RexMatchAny, _super);
    function RexMatchAny() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return RexMatchAny;
}(RexNode));
exports.RexMatchAny = RexMatchAny;
var RexChar = /** @class */ (function (_super) {
    __extends(RexChar, _super);
    function RexChar(char) {
        var _this = _super.call(this) || this;
        _this.char = char;
        return _this;
    }
    return RexChar;
}(RexNode));
exports.RexChar = RexChar;
var RexCharacterSet = /** @class */ (function (_super) {
    __extends(RexCharacterSet, _super);
    function RexCharacterSet(isNegated, chars, ranges, matchers) {
        var _this = _super.call(this) || this;
        _this.isNegated = isNegated;
        _this.chars = chars;
        _this.ranges = ranges;
        _this.matchers = matchers;
        return _this;
    }
    return RexCharacterSet;
}(RexNode));
exports.RexCharacterSet = RexCharacterSet;
var RexWhitespace = /** @class */ (function (_super) {
    __extends(RexWhitespace, _super);
    function RexWhitespace() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return RexWhitespace;
}(RexNode));
exports.RexWhitespace = RexWhitespace;
var RexNotWhitespace = /** @class */ (function (_super) {
    __extends(RexNotWhitespace, _super);
    function RexNotWhitespace() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return RexNotWhitespace;
}(RexNode));
exports.RexNotWhitespace = RexNotWhitespace;
var RexDigit = /** @class */ (function (_super) {
    __extends(RexDigit, _super);
    function RexDigit() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return RexDigit;
}(RexNode));
exports.RexDigit = RexDigit;
var RexNotDigit = /** @class */ (function (_super) {
    __extends(RexNotDigit, _super);
    function RexNotDigit() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return RexNotDigit;
}(RexNode));
exports.RexNotDigit = RexNotDigit;
var RexWord = /** @class */ (function (_super) {
    __extends(RexWord, _super);
    function RexWord() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return RexWord;
}(RexNode));
exports.RexWord = RexWord;
var RexNotWord = /** @class */ (function (_super) {
    __extends(RexNotWord, _super);
    function RexNotWord() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return RexNotWord;
}(RexNode));
exports.RexNotWord = RexNotWord;
var GroupType;
(function (GroupType) {
    GroupType["NORMAL"] = "NORMAL";
    GroupType["LOOKAHEAD"] = "LOOKAHEAD";
})(GroupType = exports.GroupType || (exports.GroupType = {}));
var RexGroup = /** @class */ (function (_super) {
    __extends(RexGroup, _super);
    function RexGroup(members, captureNames, type) {
        var _this = _super.call(this) || this;
        _this.members = members;
        _this.captureNames = captureNames;
        _this.type = type;
        return _this;
    }
    return RexGroup;
}(RexNode));
exports.RexGroup = RexGroup;
var RexOr = /** @class */ (function (_super) {
    __extends(RexOr, _super);
    function RexOr(left, right) {
        var _this = _super.call(this) || this;
        _this.left = left;
        _this.right = right;
        return _this;
    }
    return RexOr;
}(RexNode));
exports.RexOr = RexOr;
function parseRexAst(regex, captureGroups) {
    if (captureGroups === void 0) { captureGroups = []; }
    if (regex.length === 0)
        throw new Error('Cannot parse empty regular expression');
    var members = [];
    for (var i = 0; i < regex.length; i++) {
        var member = void 0;
        var char = unicodeRead(regex, i);
        i += char.length - 1;
        if (char === '\\') {
            var escapedChar = regex[++i];
            if (escapedChar === undefined) {
                throw new Error('Illegal escape sequence');
            }
            else if (escapedChar === 's') {
                member = new RexWhitespace();
            }
            else if (escapedChar === 'S') {
                member = new RexNotWhitespace();
            }
            else if (escapedChar === 'd') {
                member = new RexDigit();
            }
            else if (escapedChar === 'D') {
                member = new RexNotDigit();
            }
            else if (escapedChar === 'w') {
                member = new RexWord();
            }
            else if (escapedChar === 'W') {
                member = new RexNotWord();
            }
            else if (escapedChar === 't') {
                member = new RexChar('\t');
            }
            else if (escapedChar === 'n') {
                member = new RexChar('\n');
            }
            else if (escapedChar === 'r') {
                member = new RexChar('\r');
            }
            else {
                member = new RexChar(escapedChar);
            }
        }
        else if (char === '.') {
            member = new RexMatchAny();
        }
        else if (char === '|') {
            // upgrade this member to an OR group
            // all members seen so far belong to the left side
            // all future members belong to the right
            // transfer existing members into a `left` array
            var left = members.slice();
            // right side members are the rest of this regex
            var right = parseRexAst(regex.slice(i + 1), captureGroups);
            member = new RexOr(left, right);
            // we processed this whole regex, take control
            members.length = 0;
            members.push(member);
            break;
        }
        else if (char === '(') {
            var internalParenthesesGroupCount = 0;
            var captureName = undefined;
            var groupSequence = '';
            var validSequence = false;
            var groupType = GroupType.NORMAL;
            var peek_1 = regex[i + 1];
            if (peek_1 === '?') {
                var peekAgain = regex[i + 2];
                if (peekAgain === '<') {
                    i += 3;
                    // this is a capture group
                    // scan until end of name
                    for (i; i < regex.length; i++) {
                        var char_1 = regex[i];
                        if (char_1 === '>') {
                            // end of capture name
                            break;
                        }
                        // validate character then append it to the capture name
                        if (char_1.match(/[a-zA-Z0-9_]/) == null)
                            throw new Error('Invalid capture group name');
                        if (captureName === undefined) {
                            captureName = char_1;
                        }
                        else {
                            captureName += char_1;
                        }
                    }
                }
                else if (peekAgain === '=') {
                    i += 2;
                    groupType = GroupType.LOOKAHEAD;
                }
                else {
                    throw new Error("Invalid group modifier \"" + peekAgain + "\"");
                }
            }
            for (i = i + 1; i < regex.length; i++) {
                var char_2 = unicodeRead(regex, i);
                i += char_2.length - 1;
                if (char_2 === ')' && internalParenthesesGroupCount === 0) {
                    validSequence = true;
                    break;
                }
                else {
                    if (char_2 === '(')
                        internalParenthesesGroupCount++;
                    if (char_2 === ')')
                        internalParenthesesGroupCount--;
                    groupSequence += char_2;
                }
            }
            if (validSequence === false) {
                throw new Error('Unbalanced parentheses');
            }
            var cgroups = captureGroups.slice();
            if (captureName !== undefined) {
                cgroups.push(captureName);
            }
            member = new RexGroup(parseRexAst(groupSequence, cgroups), cgroups, groupType);
        }
        else if (char === ')') {
            throw new Error('Unexpected token ")"');
        }
        else if (char === '[') {
            var isNegated = false;
            var chars = [];
            var ranges = [];
            var matchers = [];
            var firstCharacterIndex = i + 1;
            var isValidSet = false;
            for (i = firstCharacterIndex; i < regex.length; i++) {
                var char_3 = unicodeRead(regex, i);
                i += char_3.length - 1;
                if (char_3 === ']') {
                    isValidSet = true;
                    break;
                }
                else if (char_3 === '\\') {
                    var escapedChar = regex[++i];
                    if (escapedChar === 'd') {
                        matchers.push(new Rex_1.DigitMatcher());
                    }
                    else if (escapedChar === 'D') {
                        matchers.push(new Rex_1.NegatedMatcher(new Rex_1.DigitMatcher()));
                    }
                    else if (escapedChar === 's') {
                        matchers.push(new Rex_1.WhitespaceMatcher());
                    }
                    else if (escapedChar === 'S') {
                        matchers.push(new Rex_1.NegatedMatcher(new Rex_1.WhitespaceMatcher()));
                    }
                    else if (escapedChar === 'w') {
                        matchers.push(new Rex_1.WordMatcher());
                    }
                    else if (escapedChar === 'W') {
                        matchers.push(new Rex_1.NegatedMatcher(new Rex_1.WordMatcher()));
                    }
                    else if (escapedChar === 'n') {
                        chars.push('\n');
                    }
                    else if (escapedChar === 'r') {
                        chars.push('\r');
                    }
                    else if (escapedChar === 't') {
                        chars.push('\t');
                    }
                    else {
                        chars.push(escapedChar);
                    }
                }
                else if (char_3 === '^' && i === firstCharacterIndex) {
                    isNegated = true;
                }
                else if (regex[i + 1] === '-' && regex[i + 2] !== ']') {
                    var secondChar = unicodeRead(regex, i + 2);
                    ranges.push([char_3, secondChar]);
                    i += 1 + secondChar.length;
                }
                else {
                    chars.push(char_3);
                }
            }
            if (isValidSet === false) {
                throw new Error('Invalid character set');
            }
            member = new RexCharacterSet(isNegated, chars, ranges, matchers);
        }
        else {
            member = new RexChar(char);
        }
        var peek = regex[i + 1];
        if (peek === '*') {
            i++;
            member.setMatchType(exports.MATCH_TYPE_ZERO_OR_MORE);
        }
        else if (peek === '+') {
            i++;
            member.setMatchType(exports.MATCH_TYPE_ONE_OR_MORE);
        }
        else if (peek === '?') {
            i++;
            member.setMatchType(exports.MATCH_TYPE_ZERO_OR_ONE);
        }
        if (member.getMatchType() !== exports.MATCH_TYPE_NORMAL) {
            var peek_2 = regex[i + 1];
            if (peek_2 === '?') {
                i++;
                member.isNonGreedy = true;
            }
        }
        members.push(member);
    }
    return members;
}
exports.parseRexAst = parseRexAst;
