"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ExpRex_1 = require("./exprex/ExpRex");
const rex_1 = require("@sagebrush/rex");
const whitespace = new Set([' ', '\t', '\r', '\n']);
function prettyResult(result) {
    const captures = {};
    const captureKeys = Object.keys(result.match.captures);
    for (let i = 0; i < captureKeys.length; i++) {
        const key = captureKeys[i];
        const values = result.match.captures[key];
        captures[key] = values.map(value => {
            if (value.hasOwnProperty('expression')) {
                return prettyResult(value);
            }
            else {
                return value.token;
            }
        });
    }
    return {
        isCompleteMatch: result.match.isCompleteMatch,
        expected: result.match.expected,
        matchedTokens: result.match.tokens.length,
        type: result.expression.name,
        location: result.match.tokens.length > 0
            ? {
                start: result.match.tokens[0].location.start,
                end: result.match.tokens[result.match.tokens.length - 1].location.end,
            }
            : { start: { index: 0 }, end: { index: 0 } },
        ...captures
    };
}
exports.prettyResult = prettyResult;
class Parser {
    constructor(source) {
        this.source = source;
        this.tokenMap = new Map();
        this.tokens = [];
        this.expressionMap = new Map;
        this.tokenMap.set('LINE_COMMENT', new rex_1.default('^//(?<comment>[^\r\n]+)'));
        this.tokenMap.set('BLOCK_COMMENT', new rex_1.default('^/\\*(?<comment>.*?)\\*/'));
        this.expressionMap.set('Comment', {
            name: 'Comment',
            groups: [
                new ExpRex_1.default('(?<comment>LINE_COMMENT | BLOCK_COMMENT)', this.tokenMap, this.expressionMap),
            ],
        });
        source = source.replace(/^[\t ]*#token\s+([A-Z_]+)\s+(.+?)([\r\n]+)/gm, (match, name, value, lineend) => {
            this.tokenMap.set(name, new rex_1.default('^' + value));
            return match.replace(/[^\r\n]/g, ' ');
        });
        source = source.replace(/^[\t ]*#expr\s+([a-zA-Z_]+)\s*=\s*(.+?)([\r\n]+)/gm, (match, name, subexpr, lineend) => {
            const expression = new ExpRex_1.default(`(?<comments>Comment)* ${subexpr}`, this.tokenMap, this.expressionMap);
            if (this.expressionMap.has(name)) {
                this.expressionMap.get(name).groups.push(expression);
            }
            else {
                this.expressionMap.set(name, {
                    name,
                    groups: [expression]
                });
            }
            return match.replace(/[^\r\n]/g, ' ');
        });
        this.source = source;
    }
    parse() {
        let bestMatch = undefined;
        const expression = this.expressionMap.get('Program');
        for (let i = 0; i < expression.groups.length; i++) {
            const group = expression.groups[i];
            const match = group.match(this.source, expression.name);
            if (match !== undefined) {
                if (bestMatch === undefined || match.tokens.length > bestMatch.match.tokens.length) {
                    bestMatch = { expression, match };
                }
            }
        }
        if (bestMatch)
            return prettyResult(bestMatch);
        throw new Error(`Could not parse input: no matching expression`);
    }
}
exports.default = Parser;
