"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var RexAst = require("./rexAst");
var rexAst_1 = require("./rexAst");
function codePointFromChars(chars) {
    // if single (two-byte) character, use JS built-in
    if (chars.length === 1)
        return chars.charCodeAt(0);
    // two two-byte characters, use formula
    return (chars.charCodeAt(0) - 0xD800) * 0x400 + chars.charCodeAt(1) - 0xDC00 + 0x10000;
}
var EmptyMatcher = /** @class */ (function () {
    function EmptyMatcher() {
        this.consumes = false;
    }
    EmptyMatcher.prototype.matches = function (char) {
        return true;
    };
    return EmptyMatcher;
}());
var AnyMatcher = /** @class */ (function () {
    function AnyMatcher() {
        this.consumes = true;
    }
    AnyMatcher.prototype.matches = function (char) {
        return char !== undefined;
    };
    return AnyMatcher;
}());
var CharMatcher = /** @class */ (function () {
    function CharMatcher(char) {
        this.char = char;
        this.consumes = true;
    }
    CharMatcher.prototype.matches = function (char) {
        return this.char === char;
    };
    return CharMatcher;
}());
exports.CharMatcher = CharMatcher;
var CharacterSetMatcher = /** @class */ (function () {
    function CharacterSetMatcher(characterSet) {
        this.consumes = true;
        this.isNegated = characterSet.isNegated;
        this.chars = new Set(characterSet.chars);
        this.ranges = characterSet.ranges.map(function (_a) {
            var start = _a[0], end = _a[1];
            return ({
                start: codePointFromChars(start),
                end: codePointFromChars(end),
            });
        });
        this.matchers = characterSet.matchers;
    }
    CharacterSetMatcher.prototype.matches = function (char) {
        if (char === undefined)
            return false;
        var result = false;
        if (this.chars.has(char))
            result = true;
        if (result === false) {
            var charCode = char !== undefined ? codePointFromChars(char) : -1;
            for (var i = 0; i < this.ranges.length; i++) {
                var _a = this.ranges[i], start = _a.start, end = _a.end;
                if (start <= charCode && charCode <= end) {
                    result = true;
                    break;
                }
            }
        }
        if (result === false) {
            for (var i = 0; i < this.matchers.length; i++) {
                var matcher = this.matchers[i];
                if (matcher.matches(char)) {
                    result = true;
                    break;
                }
            }
        }
        return this.isNegated ? !result : result;
    };
    return CharacterSetMatcher;
}());
var WhitespaceMatcher = /** @class */ (function () {
    function WhitespaceMatcher() {
        this.consumes = true;
    }
    WhitespaceMatcher.prototype.matches = function (char) {
        return char === ' ' || char === '\t' || char === '\n' || char === '\r';
    };
    return WhitespaceMatcher;
}());
exports.WhitespaceMatcher = WhitespaceMatcher;
var charCode0 = '0'.charCodeAt(0);
var charCode9 = '9'.charCodeAt(0);
var DigitMatcher = /** @class */ (function () {
    function DigitMatcher() {
        this.consumes = true;
    }
    DigitMatcher.prototype.matches = function (char) {
        var charCode = char ? codePointFromChars(char) : -1;
        return charCode0 <= charCode && charCode <= charCode9;
    };
    return DigitMatcher;
}());
exports.DigitMatcher = DigitMatcher;
var wordChars = new Set([
    'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z',
    'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z',
    '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '_'
]);
var WordMatcher = /** @class */ (function () {
    function WordMatcher() {
        this.consumes = true;
    }
    WordMatcher.prototype.matches = function (char) {
        return char !== undefined ? wordChars.has(char) : false;
    };
    return WordMatcher;
}());
exports.WordMatcher = WordMatcher;
var NegatedMatcher = /** @class */ (function () {
    function NegatedMatcher(matcher) {
        this.matcher = matcher;
        this.consumes = true;
    }
    NegatedMatcher.prototype.matches = function (char) {
        if (char === undefined)
            return false;
        return !this.matcher.matches(char);
    };
    return NegatedMatcher;
}());
exports.NegatedMatcher = NegatedMatcher;
var StateConnection = /** @class */ (function () {
    function StateConnection(matcher, node) {
        this.matcher = matcher;
        this.node = node;
    }
    return StateConnection;
}());
exports.StateConnection = StateConnection;
var nextMatchCounterId = 0;
var nextNodeId = 0;
var StateNode = /** @class */ (function () {
    function StateNode() {
        this.nodeId = nextNodeId++;
        this.captureNames = new Set();
        this.connections = [];
        this.appendMatch = true;
    }
    StateNode.prototype.connect = function (matcher, node) {
        this.connections.push(new StateConnection(matcher, node));
    };
    StateNode.prototype.setMatchCounterId = function (matchCounterId) {
        this.matchCounterId = matchCounterId;
        for (var i = 0; i < this.connections.length; i++) {
            this.connections[i].node.setMatchCounterId(matchCounterId);
        }
    };
    StateNode.prototype.addCaptureNames = function (names, processedNodes) {
        if (processedNodes === void 0) { processedNodes = new Set(); }
        if (processedNodes.has(this))
            return;
        processedNodes.add(this);
        for (var i = 0; i < names.length; i++) {
            this.captureNames.add(names[i]);
        }
        for (var i = 0; i < this.connections.length; i++) {
            this.connections[i].node.addCaptureNames(names, processedNodes);
        }
    };
    StateNode.prototype.setAppendMatch = function (appendMatch, processedNodes) {
        if (processedNodes === void 0) { processedNodes = new Set(); }
        if (processedNodes.has(this))
            return;
        processedNodes.add(this);
        this.appendMatch = appendMatch;
        for (var i = 0; i < this.connections.length; i++) {
            this.connections[i].node.setAppendMatch(appendMatch, processedNodes);
        }
    };
    return StateNode;
}());
exports.StateNode = StateNode;
var EndStateNode = /** @class */ (function (_super) {
    __extends(EndStateNode, _super);
    function EndStateNode() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return EndStateNode;
}(StateNode));
exports.EndStateNode = EndStateNode;
var MATCH_STATUS_INITIALIZED = Symbol('MATCH_STATUS_INITIALIZED');
var MATCH_STATUS_SUCCESS = Symbol('MATCH_STATUS_SUCCESS');
var MATCH_STATUS_FAIL = Symbol('MATCH_STATUS_FAIL');
function isNewCountersBetter(oldCounters, newCounters) {
    var maxCounterId = Math.max(oldCounters.length, newCounters.length);
    for (var i = 0; i < maxCounterId; i++) {
        if ((oldCounters[i] === undefined &&
            newCounters[i] !== undefined)
            ||
                (oldCounters[i] !== undefined &&
                    newCounters[i] !== undefined &&
                    newCounters[i] > oldCounters[i])) {
            return false;
        }
    }
    return true;
}
var MatchState = /** @class */ (function () {
    function MatchState(node, against, cursor, cursorStart, matchCounters, captures, seenStates, matchedText, totalConsumedLength) {
        if (cursorStart === void 0) { cursorStart = cursor; }
        if (matchCounters === void 0) { matchCounters = []; }
        if (captures === void 0) { captures = {}; }
        if (seenStates === void 0) { seenStates = new Set(); }
        if (matchedText === void 0) { matchedText = ''; }
        if (totalConsumedLength === void 0) { totalConsumedLength = 0; }
        this.node = node;
        this.against = against;
        this.cursor = cursor;
        this.cursorStart = cursorStart;
        this.matchCounters = matchCounters;
        this.captures = captures;
        this.seenStates = seenStates;
        this.matchedText = matchedText;
        this.totalConsumedLength = totalConsumedLength;
        this.status = MATCH_STATUS_INITIALIZED;
        this.nextStates = [];
    }
    MatchState.prototype.advance = function () {
        if (this.status === MATCH_STATUS_FAIL)
            throw new Error('Cannot advance MatchState, already failed');
        if (this.status === MATCH_STATUS_SUCCESS)
            throw new Error('Cannot advance MatchState, already succeeded');
        var nextChar = RexAst.unicodeRead(this.against, this.cursor);
        var advanceSize = nextChar !== undefined ? nextChar.length : 1;
        var myState = this.node.nodeId + "-" + this.cursor + "-" + this.matchCounters;
        if (this.seenStates.has(myState)) {
            this.status = MATCH_STATUS_FAIL;
            return;
        }
        this.seenStates.add(myState);
        var _loop_1 = function (i) {
            var connection = this_1.node.connections[i];
            if (connection.matcher.matches(nextChar)) {
                if (connection.matcher instanceof EmptyMatcher === false || connection.node !== this_1.node) {
                    var connectedNode = connection.node;
                    var appendMatch = connection.node.appendMatch;
                    // increment any active match counter
                    var applyMatchCounter = connection.matcher.consumes && connectedNode.matchCounterId !== undefined;
                    var matchCounters = applyMatchCounter ? this_1.matchCounters.slice() : this_1.matchCounters; // only clone if being modified
                    if (applyMatchCounter) {
                        matchCounters[connectedNode.matchCounterId] = matchCounters[connectedNode.matchCounterId] || 0;
                        matchCounters[connectedNode.matchCounterId]++;
                    }
                    // append character to any active capture groups
                    var applyCaptures = appendMatch && connection.matcher.consumes && connectedNode.captureNames.size > 0;
                    var captures_1 = applyCaptures ? __assign({}, this_1.captures) : this_1.captures; // only clone if being modified
                    if (applyCaptures) {
                        connectedNode.captureNames.forEach(function (name) {
                            if (captures_1.hasOwnProperty(name) === false)
                                captures_1[name] = '';
                            captures_1[name] += nextChar;
                        });
                    }
                    var matchedText = connection.matcher.consumes ? (appendMatch ? this_1.matchedText + (nextChar || '') : this_1.matchedText) : this_1.matchedText;
                    this_1.nextStates.push(new MatchState(connectedNode, this_1.against, this_1.cursor + (connection.matcher.consumes ? advanceSize : 0), this_1.cursorStart, matchCounters, captures_1, this_1.seenStates, matchedText, this_1.totalConsumedLength + (connection.matcher.consumes ? 1 : 0)));
                }
            }
        };
        var this_1 = this;
        for (var i = 0; i < this.node.connections.length; i++) {
            _loop_1(i);
        }
        if (this.nextStates.length === 0) {
            this.status = MATCH_STATUS_FAIL;
        }
        else {
            this.status = MATCH_STATUS_SUCCESS;
        }
    };
    Object.defineProperty(MatchState.prototype, "isSuccessful", {
        get: function () {
            return this.status === MATCH_STATUS_SUCCESS;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MatchState.prototype, "isAtEnd", {
        get: function () {
            return this.node instanceof EndStateNode;
        },
        enumerable: true,
        configurable: true
    });
    MatchState.prototype.getNextStates = function () {
        if (this.status !== MATCH_STATUS_SUCCESS)
            throw new Error("Cannot get next states, MatchState is " + this.status.toString());
        return this.nextStates;
    };
    MatchState.prototype.getMatchedText = function () {
        return this.matchedText;
    };
    Object.defineProperty(MatchState.prototype, "isAtEndOfInputString", {
        get: function () {
            return this.cursor === this.against.length;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MatchState.prototype, "result", {
        get: function () {
            return {
                text: this.getMatchedText(),
                captures: this.captures,
            };
        },
        enumerable: true,
        configurable: true
    });
    return MatchState;
}());
function assertNever(x) {
    throw new Error('Runtime Type Error');
}
// all paths connect the same exit node so its safe to always choose the first unseen connection
function collectExitNode(tree) {
    var seenNodes = new Set();
    var exitNode = tree;
    while (exitNode.connections.length > 0) {
        for (var i = 0; i < exitNode.connections.length; i++) {
            // avoid infinite recursion into the same node
            var node = exitNode.connections[i].node;
            if (seenNodes.has(node) === false) {
                seenNodes.add(node);
                exitNode = exitNode.connections[i].node;
                break;
            }
        }
    }
    return exitNode;
}
var Rex = /** @class */ (function () {
    function Rex(regex) {
        if (regex.length === 0)
            throw new Error('Cannot create regex from zero-length string');
        this.isBoundToStart = regex[0] === '^';
        if (this.isBoundToStart) {
            regex = regex.slice(1);
        }
        this.isBoundToEnd = regex[regex.length - 1] === '$';
        if (this.isBoundToEnd) {
            regex = regex.slice(0, regex.length - 1);
        }
        this.stateTree = Rex.buildFromSequence(regex);
        Rex.compressMatchers(this.stateTree);
    }
    Rex.compressMatchers = function (tree, seenNodes) {
        if (seenNodes === void 0) { seenNodes = new Set(); }
        if (seenNodes.has(tree))
            return;
        seenNodes.add(tree);
        // walk through tree, compressing it by removing all EmptyMatchers
        for (var i = 0; i < tree.connections.length; i++) {
            var connection = tree.connections[i];
            // if the matcher is an EmptyMatcher _and_ the connecting node isn't an EndStateNode
            if (connection.matcher instanceof EmptyMatcher && connection.node instanceof EndStateNode === false) {
                // this connection always progresses into the connected node
                // remove this connection and add all of the connected node's connections
                tree.connections.splice(i, 1);
                Array.prototype.push.apply(tree.connections, connection.node.connections);
                // we removed a connection, step backwards in the count
                i--;
            }
            else {
                // keeping this connection around, process its node
                Rex.compressMatchers(connection.node, seenNodes);
            }
        }
    };
    Rex.buildFromMembers = function (members, endNode) {
        if (endNode === void 0) { endNode = new EndStateNode(); }
        var stateTree = new StateNode();
        var previousNodes = [stateTree];
        var nextNodes;
        var _loop_2 = function (i) {
            nextNodes = [];
            var matcher;
            var member = members[i];
            // in most cases entry and exit are the same node
            var entryNode = new StateNode();
            var exitNode = entryNode;
            if (member instanceof RexAst.RexChar) {
                matcher = new CharMatcher(member.char);
            }
            else if (member instanceof RexAst.RexMatchAny) {
                matcher = new AnyMatcher();
            }
            else if (member instanceof RexAst.RexWhitespace) {
                matcher = new WhitespaceMatcher();
            }
            else if (member instanceof RexAst.RexNotWhitespace) {
                matcher = new NegatedMatcher(new WhitespaceMatcher());
            }
            else if (member instanceof RexAst.RexDigit) {
                matcher = new DigitMatcher();
            }
            else if (member instanceof RexAst.RexNotDigit) {
                matcher = new NegatedMatcher(new DigitMatcher());
            }
            else if (member instanceof RexAst.RexWord) {
                matcher = new WordMatcher();
            }
            else if (member instanceof RexAst.RexNotWord) {
                matcher = new NegatedMatcher(new WordMatcher());
            }
            else if (member instanceof RexAst.RexGroup) {
                matcher = new EmptyMatcher();
                var groupTree = Rex.buildFromMembers(member.members, new StateNode());
                groupTree.addCaptureNames(member.captureNames);
                if (member.type === rexAst_1.GroupType.LOOKAHEAD) {
                    groupTree.setAppendMatch(false);
                }
                // entryNode is the group entry
                entryNode = groupTree;
                exitNode = collectExitNode(groupTree);
            }
            else if (member instanceof RexAst.RexOr) {
                matcher = new EmptyMatcher();
                // entry & exist nodes are handled separately below
            }
            else if (member instanceof RexAst.RexCharacterSet) {
                matcher = new CharacterSetMatcher(member);
            }
            else {
                assertNever(member);
                matcher = new EmptyMatcher();
            }
            var matchType = member.getMatchType();
            if (member instanceof RexAst.RexOr) {
                // validate this or node doesn't have special matching
                // as that should be very impossible
                if (matchType !== RexAst.MATCH_TYPE_NORMAL)
                    throw new Error('RexOr node must be a MATCH_TYPE_NORMAL');
                var leftTree_1 = Rex.buildFromMembers(member.left, new StateNode());
                var rightTree_1 = Rex.buildFromMembers(member.right, new StateNode());
                previousNodes.forEach(function (prev) {
                    prev.connect(matcher, leftTree_1);
                    prev.connect(matcher, rightTree_1);
                });
                nextNodes.push(collectExitNode(leftTree_1));
                nextNodes.push(collectExitNode(rightTree_1));
            }
            else if (matchType === RexAst.MATCH_TYPE_ZERO_OR_MORE) {
                if (member.isNonGreedy) {
                    entryNode.setMatchCounterId(nextMatchCounterId++);
                }
                // connect all previous nodes to this node (this is the matching path)
                previousNodes.forEach(function (node) { return node.connect(matcher, entryNode); });
                // create the optional path through
                var emptyNode_1 = new StateNode();
                previousNodes.forEach(function (node) { return node.connect(new EmptyMatcher(), emptyNode_1); });
                // connect node's exit to its entry with the same matcher (this is the repeating path)
                exitNode.connect(matcher, entryNode);
                nextNodes.push(exitNode); // expose matched path
                nextNodes.push(emptyNode_1); // expose the optional escape path
            }
            else if (matchType === RexAst.MATCH_TYPE_ONE_OR_MORE) {
                if (member.isNonGreedy) {
                    entryNode.setMatchCounterId(nextMatchCounterId++);
                }
                // have to match once
                // each previous node followers matcher into the required node
                previousNodes.forEach(function (node) { return node.connect(matcher, entryNode); });
                // the entry path can be moved on from
                nextNodes.push(exitNode);
                // this node's exit can repeat via the same matcher
                exitNode.connect(matcher, entryNode);
            }
            else if (matchType === RexAst.MATCH_TYPE_ZERO_OR_ONE) {
                if (member.isNonGreedy) {
                    entryNode.setMatchCounterId(nextMatchCounterId++);
                }
                // connect all previous nodes to this node (this is the matching path)
                previousNodes.forEach(function (node) { return node.connect(matcher, entryNode); });
                // create the optional path through
                var emptyNode_2 = new StateNode();
                previousNodes.forEach(function (node) { return node.connect(new EmptyMatcher(), emptyNode_2); });
                nextNodes.push(exitNode); // expose matched path
                nextNodes.push(emptyNode_2); // expose the optional escape path
            }
            else if (matchType === RexAst.MATCH_TYPE_NORMAL) {
                nextNodes.push(exitNode);
                previousNodes.forEach(function (node) { return node.connect(matcher, entryNode); });
            }
            else {
                assertNever(matchType);
            }
            previousNodes = nextNodes;
        };
        for (var i = 0; i < members.length; i++) {
            _loop_2(i);
        }
        previousNodes.forEach(function (node) { return node.connect(new EmptyMatcher(), endNode); });
        return stateTree;
    };
    Rex.buildFromSequence = function (sequence) {
        if (sequence.length === 0)
            throw new Error('Cannot create sequence from zero-length string');
        var members = RexAst.parseRexAst(sequence);
        return Rex.buildFromMembers(members);
    };
    Rex.prototype.match = function (against, cursor) {
        if (cursor === void 0) { cursor = 0; }
        do {
            var match = this.matchAt(against, cursor);
            if (match !== undefined) {
                return match;
            }
        } while (++cursor < against.length && this.isBoundToStart === false);
        return undefined;
    };
    Rex.prototype.matchAt = function (against, cursor) {
        var states = [new MatchState(this.stateTree, against, cursor)];
        var bestMatchLength = -Infinity;
        var bestMatch;
        while (states.length > 0) {
            var state = states.shift();
            state.advance();
            if (state.isAtEnd) {
                var match = state.getMatchedText();
                if (bestMatch === undefined || state.totalConsumedLength >= bestMatchLength) {
                    if (!this.isBoundToEnd || state.isAtEndOfInputString) {
                        if (bestMatch === undefined) {
                            bestMatch = state;
                            bestMatchLength = state.totalConsumedLength;
                        }
                        else if (isNewCountersBetter(bestMatch.matchCounters, state.matchCounters)) {
                            bestMatch = state;
                            bestMatchLength = state.totalConsumedLength;
                        }
                    }
                }
            }
            else if (state.isSuccessful) {
                var nextStates = state.getNextStates();
                Array.prototype.push.apply(states, nextStates);
            }
        }
        return bestMatch ? bestMatch.result : undefined;
    };
    return Rex;
}());
exports.default = Rex;
